import { saveScrollPos } from "hooks/useScrollRestoration";
import React, { useEffect, useRef } from "react";
import NextLink from 'next/link';

function Link(props) {
  const ref = useRef();

  const childElement = React.Children.only(props.children);
  useEffect(() => {
    const _ref = ref.current;

    function onClick() {
      // ScrollRestore niet gebruiken
      if(props.scroll===false)
      {
        window._scrollRestore = 'none';
        saveScrollPos(props.href, 0, 0);
      }
    }

    _ref.addEventListener("click", onClick);

    return () => {
      _ref.removeEventListener("click", onClick);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NextLink {...props} legacyBehavior>{
      React.cloneElement(
        childElement, 
        {
          ref,
        }
      )
    }</NextLink>
  );
}

export default Link;