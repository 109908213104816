import { useEffect, useState } from "react";
import Router from 'next/router'
import styles from './style';

function LoadingBar(props) {
  const [visible, setVisible] = useState(false);

  function routeChangeStart() {
    setVisible(true);
  }
  function routeChangeComplete() {
    setVisible(false);
  }
  function routeChangeError() {
    setVisible(false);
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeComplete);
    Router.events.on('routeChangeError', routeChangeError);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeComplete);
      Router.events.off('routeChangeError', routeChangeError);
    }
  });

  return (
    <div className="loading-bar" style={props.style}>
      <div className={"progress" + (visible ? " visible": "")}></div>
      <style jsx>{styles}</style>
    </div>
  )
}

export default LoadingBar;