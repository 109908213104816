import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';

function saveScrollPos(url, x, y) {
  x = x || window.scrollX;
  y = y || window.scrollY;
  
  const scrollPos = { x, y };
  sessionStorage.setItem(url, JSON.stringify(scrollPos));
}

function restoreScrollPos(url) {
  if(window?._scrollRestore==='none')
  {
    delete window._scrollRestore;
    return;
  }
  
  const scrollPos = JSON.parse(sessionStorage.getItem(url));
  if (scrollPos) {
    document.body.style.minHeight = (window.innerHeight + scrollPos.y) + 'px';

    requestAnimationFrame(() => {
      window.scrollTo(scrollPos.x, scrollPos.y);
    });
  }else
  {
    window.scrollTo(0,0);
  }
}

export default function useScrollRestoration() {  
  const router = useRouter();

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      let shouldScrollRestore = false;
      window.history.scrollRestoration = 'auto';
      restoreScrollPos(router.asPath);

      const onBeforeUnload = event => {
        document.body.style.minHeight = '';
        saveScrollPos(router.asPath);
        delete event['returnValue'];
      };

      const onRouteChangeStart = () => {
        document.body.style.minHeight = '';
        saveScrollPos(router.asPath);
      };

      const onRouteChangeComplete = url => {        
        if (shouldScrollRestore) {
          shouldScrollRestore = false;
          restoreScrollPos(url);
        }
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      Router.events.on('routeChangeStart', onRouteChangeStart);
      Router.events.on('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(() => {
        shouldScrollRestore = true;
        return true;
      });

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        Router.events.off('routeChangeStart', onRouteChangeStart);
        Router.events.off('routeChangeComplete', onRouteChangeComplete);
        Router.beforePopState(() => true);
      };
    }
  }, [router]);

  return () => {
    document.body.style.minHeight = '';
  }
}

export {
  saveScrollPos,
}