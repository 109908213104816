import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import styles from './style';
import { getDataProps } from '../utils';
import Link from 'next/link';

type Props = {
  icon: any;
  style?: React.CSSProperties,
  iconSize?: string,
  label?: string,
  iconBalloon?: {
    value: number,
  },
  more?: boolean,
  className?: string,
  onPointerDown?: (event: React.PointerEvent<HTMLElement>) => void,
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  href?: string,
}

const IconButton = forwardRef<any, Props>((props, ref) => {
  const style = {pointerEvents: "none", ...props.style} as React.CSSProperties;
  if(props.iconSize)
    style['fontSize'] = props.iconSize;

  const cleanProps = {
    className: ["icon-button", props.className].join(" "),
    onClick: props.onClick,
    onPointerDown: props.onPointerDown,
    ...getDataProps(props)
  };
  
  const content = (
    <>
      <div className="icon-button-icon">
        <FontAwesomeIcon icon={props.icon} style={style} fixedWidth />
        {
          props.iconBalloon?.value > 0 &&
          <div className="icon-button-balloon">{isNaN(props.iconBalloon.value) ? props.iconBalloon.value : props.iconBalloon.value > 9 ? "9+": props.iconBalloon.value}</div>
        }
      </div>

      {props.label && <div className="label">{props.label}</div>}

      {
        props.more && (
          <div className="more"><FontAwesomeIcon icon={faCaretDown} style={{pointerEvents: "none"}} fixedWidth /></div>
        )
      }
      <style jsx>{styles}</style>
    </>
  );

  if(props.href)
  {
    return (
      <Link href={props.href} legacyBehavior>
        <a ref={ref} {...cleanProps}>
          {content}
          <style jsx>{styles}</style>
        </a>
      </Link>
    )
  }else
  {
    return (
      <div ref={ref} {...cleanProps}>
        {content}
        <style jsx>{styles}</style>
      </div>
    );
  }
});

IconButton.displayName = 'IconButton';
export default IconButton;