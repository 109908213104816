import { useRef, useEffect, useCallback } from "react";
import { Menu, IconButton, getTheme } from 'components/UI';
import { useShop } from "stores/shop/shop";
import { useUser } from 'utils/user/lib';
import { Observer } from "mobx-react";
import { faShoppingCart } from "@fortawesome/pro-light-svg-icons/faShoppingCart";
import { currencyFormat } from 'utils/format';
import translationStore from "stores/translation-store";
import jsCookies from "js-cookie";

function CartMenuItem(props)
{
  const button = useRef(null);
  const shop = useShop();
  const user = useUser();
  const [isMenuOpen, setShowMenu] = Menu.useOpener();

  useEffect(() =>
  {
    if(props.showBalloon)
    {
      const cartKey = jsCookies.get("shoppingcart") || ""; // Get cart key from cookie
      shop.loadCartFromUserWhenNeeded(cartKey, user); // Load cart from user when needed
    }
  }, [user, user.isLoggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

  const showMenu = useCallback(() => {
    setShowMenu(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
    {
      () => {
        const totalPrice = currencyFormat(translationStore.getLanguage(), shop.cart.currency, (shop.cart.pricing.total / 100))

        return (
          <>
            <IconButton
              ref={button}
              label=""
              icon={faShoppingCart}
              iconSize={18}
              iconBalloon={{
                bg: getTheme().accentColor,
                color: getTheme().textColor,
                value: props.showBalloon && shop.productCount
              }}
              className="menu-bar-button"
              onPointerDown={showMenu}
            />
      
            <Menu
              roundedBorder={[false, false, true, true]}
              position={{
                ref: button,
                hAlign: "right",
                vAlign: "bottom",
                x: 11,
                y: -1,
              }}
              onRequestClose={() => setShowMenu(false)}
              isOpen={isMenuOpen}
              isLarge={true}
              maxWidth={320}
              style={{
                width: 320,
              }}
            >

              <Menu.TextBlock
                title={translationStore.translate('minicart.title')}
                text={translationStore.translate('minicart.text', {count: shop.productCount, totalPrice: totalPrice})}
              />
              
              <Menu.Link
                title={translationStore.translate('minicart.view-cart')}
                href="/shop/cart"
                fullWidth={true}
              />

              {props.items?.length>0 && Menu.ArrayItems(props.items)}
            </Menu>
          </>
        )
      }
    }
    </Observer>
  )
}

CartMenuItem.defaultProps = {
  showBalloon: true
}

export default CartMenuItem;