import { useRef, useCallback } from "react";
import Color from "utils/Color";
import Avatar from "components/Avatar/Avatar";
import { whenAuthenticated } from "components/Authentication";
import { Menu, getTheme, Modal } from 'components/UI'
import { useUser } from 'utils/user/lib';
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { observer } from "mobx-react";
import translationStore from "stores/translation-store";
import { useAvatars } from "stores/avatar-store";
import { toJS } from "mobx";


function UserMenuItem() {
  const user = useUser();
  const button = useRef();
  const [isMenuOpen, setShowMenu] = Menu.useOpener();

  const onUpgradeButtonClick = useCallback(() => {
    const link = user.subscription.type==='premium' ? '/business?redirect=1&ref=miniprofile' : '/premium?redirect=1&ref=miniprofile';
    const url = process.env.SITE_URL + link;
    location.href = url;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showMenu = useCallback(() => {
    setShowMenu(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleLogin()
  {
    if(user.isLoggedIn)
    {
      user.logout();
      return;
    }

    whenAuthenticated(user).catch(() => {});
  }

  return (
    <>
      <div ref={button} onPointerDown={showMenu} className="button">
        <UserAvatar />
      </div>

      <Menu
        roundedBorder={[false, false, true, true]}
        position={{
          ref: button,
          hAlign: "right",
          vAlign: "bottom",
          x: 0,
          y: -1,
        }}
        onRequestClose={() => setShowMenu(false)}
        isOpen={isMenuOpen}
        isLarge={true}
        maxWidth={320}
        style={{
          width: 320,
        }}
      >
        {
          user.isLoggedIn && (
            <Menu.TextBlock
              title={translationStore.translate('member.subscription.name.' + user.subscription.type)}
              text={translationStore.translate('member.miniprofile.text.' + user.subscription.type)}
            />
          )
        }

        {
          (user.isLoggedIn && user.subscription.type !== "pro") && (
            <Menu.Button
              title={translationStore.translate('member.miniprofile.upgrade.' + user.subscription.type)}
              onClick={onUpgradeButtonClick}
              fullWidth={true}
            />
          )
        }

        {
          user.isLoggedIn && (
            <Menu.Separator />
          )
        }

        <Menu.Submenu
          title={translationStore.translate("context.language")}
          icon={faGlobe}
        >
          <Menu.Item
            title="Nederlands"
            onClick={() => {
              translationStore.setLanguage('nl');
            }}
          />
          <Menu.Item
            title="English"
            onClick={() => {
              translationStore.setLanguage('en');
            }}
          />
          <Menu.Item
            title="Français"
            onClick={() => {
              translationStore.setLanguage('fr');
            }}
          />
          <Menu.Item
            title="Deutsch"
            onClick={() => {
              translationStore.setLanguage('de');
            }}
          />
          <Menu.Item
            title="Español"
            onClick={() => {
              translationStore.setLanguage('es');
            }}
          />
        </Menu.Submenu>

        { user.isLoggedIn && 
          <Menu.Item
            title={translationStore.translate("member.profile")}
            onClick={() => {
              location.href = `${process.env.SITE_URL}/member/profile`;
            }}
          />
        }

        <Menu.Separator />

        <Menu.Item
          title={translationStore.translate("menu.need-help")}
          onClick={() => {
            Modal.Help();
          }}
        />

        <Menu.Item
          title={user.isLoggedIn ? translationStore.translate("authentication.logout") : translationStore.translate("authentication.login")}
          onClick={() => {
            toggleLogin()
          }}
        />
      </Menu>
    </>
  );
}

function UserAvatar() {
  const user = useUser();
  const avatars = useAvatars();

  if (user.isLoggedIn && user.avatar?.initials) {
    if(!avatars.isLoaded(user.key)) {
      const avatarData = Object.assign({}, user.avatar);
      if(avatarData.image) {
        avatarData.images = Object.assign({}, avatarData.image);
        delete avatarData.image;
      }

      avatars.addAvatar(user.key, avatarData);
    }

    return (
      <Avatar
        size={40}
        initials={user.avatar.initials}
        color={user.avatar.color}
        images={user.avatar.image}
      />
    );
  }

  return (
    <Avatar
      size={40}
      color={Color.rgbToHex(getTheme().accentColorMedium)}
      icon={faUser}
    />
  );
}


export default observer(UserMenuItem);