import { useState, useRef, useEffect, forwardRef, useCallback } from "react";
import { Observer } from "mobx-react";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, IconButton, LoadingBar, Tag } from 'components/UI'
import NotificationMenuItem from 'components/Notifications/NotificationMenuItem/NotificationMenuItem';
import UserMenuItem from 'components/UI/UserMenuItem/UserMenuItem';
import CartMenuItem from 'components/UI/CartMenuItem/CartMenuItem';
import useWindowSize from "hooks/useWindowSize";

import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faEllipsisV as moreMenuIcon } from "@fortawesome/pro-light-svg-icons/faEllipsisV";
import style, {backStyle, dividerStyle} from "./style";
import { useUser } from "utils/user/lib";
import translationStore from "stores/translation-store";
import Link from "components/Link";

function LogoMenuItem(props) {
  const user = useUser();
  const classNames = ["menu-bar-button"];

  if(props.full!==undefined)
    classNames.push("full");

  const resetScroll = useCallback(() => {  // eslint-disable-line react-hooks/exhaustive-deps
    window._scrollRestore = 'none';
  });

  return (
    <>
      <Link
        href={props.href}
        scroll={false}
      >
        <a
          className={classNames.join(" ")}
          onMouseDown={resetScroll}
        >
          <div className="myalbum-logo" />
        </a>
      </Link>
      {
        (user.isLoggedIn && props.subscription) && (
          <Tag
            type="light-outline"
            href={user.subscription.name.toLowerCase() === "pro" ? undefined : process.env.SITE_URL + translationStore.translate("urls.pricing") + "?redirect=1&ref=tagline"}
          >
            {user.subscription.name}
          </Tag>
        )
      }
    </>
  );
}

LogoMenuItem.defaultProps = {
  href: "/myalbums/"
}

function MoreMenuItem(props) {
  const [moreMenuOpen, setMoreMenuOpen] = Menu.useOpener();
  const [items, setItems] = useState([]);
  const moreMenuRef = useRef(null);

  function openMenu() {
    setMoreMenuOpen(true);
  }

  useEffect(() => {
    const filteredMoreMenuItems = props.items.filter(item => {
      if(item.visible!==false)
        return item;
    });

    setItems(filteredMoreMenuItems);
  }, [props.items])

  if(items.length===0)
    return null;

  return (
    <>
      <IconButton
        label=""
        icon={moreMenuIcon}
        iconSize={30}
        ref={moreMenuRef}
        onPointerDown={openMenu}
        className="menu-bar-button small more"
      />
      <Menu
        roundedBorder={[false, false, true, true]}
        isOpen={moreMenuOpen}
        position={{ref: moreMenuRef, hAlign: "right", vAlign: "bottom", y: -1, x: 21}}
        onRequestClose={() => setMoreMenuOpen(false)}
      >
        {items.length>0 && Menu.ArrayItems(items)}
      </Menu>
    </>
  )
}
MoreMenuItem.defaultProps = {
  items: [],
}

function MenuBar(props)
{
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width<571;

  function getComponent(key) {
    let children = props.children ?  Array.isArray(props.children) ? props.children : [props.children] : [];

    for(let child of children) {
      if(child.key===key)
      {
        return child.props.children;
      }
    }
  }

  return (
    <Observer>
    {
      () => {
        const arrowPosition = isNaN(props.arrowPosition) ? 0 : props.arrowPosition - 5.5;
        const left = getComponent('left');
        const center = getComponent('center');
        const right = getComponent('right');
        const mobile = getComponent('mobile');

        return (
          <>
            {
              !isSmallScreen && (
                <div className={"menu-bar bigscreen " + (!props.visible ? "hide" : "")} ref={ref}>
                  <div className="menu-bar-left">
                    {left}
                  </div>
                  <div className="menu-bar-center">
                    {center}
                  </div>
                  <div className="menu-bar-right">
                    {right}
                    <div style={{width: 10}}/>
                    <NotificationMenuItem />
                    <UserMenuItem />
                  </div>
                  <div className="border">
                    {
                      arrowPosition > 0 && (
                        <>
                          <div className="left" style={{width: arrowPosition}}></div>
                          <div className="arrow">
                            <svg viewBox="0 0 16 8">
                              <path fill="none" strokeWidth={2} stroke={"rgba(var(--border1), 1)"} d="M0,8 L8,0 L16,8" />
                              <path fill="rgba(var(--menuBg), 1)" d="M0,8 L0,0 L16,0 L16,8 L8,0z" />
                            </svg>
                          </div>
                      </>
                      )
                    }
                    <div className="right"></div>
                  </div>
                </div>
              )
            }

            {
              isSmallScreen && (
                <div className={"menu-bar smallscreen "  + (!props.visible ? "hide" : "")}>
                  {mobile}

                  <div className="menu-bar-fixed-end">
                    <NotificationMenuItem />
                    <UserMenuItem />
                  </div>

                  <div className="border">
                    {
                      arrowPosition > 0 && (
                        <>
                          <div className="left" style={{width: arrowPosition}}></div>
                          <div className="arrow">
                            <svg viewBox="0 0 11 5">
                              <path fill="none" strokeWidth={1.8} stroke={"rgba(var(--border1), 1)"} d="M0,5 L5.5,0 L11,5" />
                              <path fill="rgba(var(--menuBg), 1)" d="M0,5 L0,0 L11,0 L11,5 L5.5,0z" />
                            </svg>
                          </div>
                          </>
                      )
                    }
                    <div className="right"></div>
                  </div>
                </div>
              )
            }

            {props.loadingBar && <LoadingBar />}

            <style jsx>{style}</style>
          </>
        );
      }
    }
    </Observer>
  );
}

MenuBar.defaultProps = {
  visible: true,
  loadingBar: true,
}

MenuBar.Divider = function Divider()
{
  return (
    <>
      <div className="divider" />
      <style jsx>{dividerStyle}</style>
    </>
  );
}

MenuBar.Spacer = function Divider()
{
  return (
    <>
      <div className="divider" style={{border: 0}} />
      <style jsx>{style}</style>
    </>
  );
}

MenuBar.Back = function Back(props)
{
  const router = useRouter();
  function goBack() {
    if(props.onClick)
      props.onClick();
    else
      router.back();
  }

  if(props.href) {
    return (
      <Link href={props.href}>
        <a className="button back">
          <FontAwesomeIcon icon={faChevronLeft} style={{pointerEvents: "none"}} />
          <style jsx>{backStyle}</style>
        </a>
      </Link>
    );
  }

  return (
    <div className="button back" onClick={goBack}>
      <FontAwesomeIcon icon={faChevronLeft} style={{pointerEvents: "none"}} />
      <style jsx>{backStyle}</style>
    </div>
  );
}

MenuBar.Cart = function Cart(props)
{
  return <CartMenuItem {...props} />;
}

MenuBar.Logo = function Logo(props) {
  return <LogoMenuItem {...props} />;
}

MenuBar.More = function More(props) {
  return <MoreMenuItem {...props} />;
}

MenuBar.Button = forwardRef((props, ref) => {
  return (
    <div className="button" ref={ref} onClick={props.onClick} style={{paddingLeft: 15, paddingRight: 15}}>
      {props.children}
    </div>
  )
});
MenuBar.Button.displayName = 'MenuBarButton';

export default MenuBar;