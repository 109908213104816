import css from 'styled-jsx/css'
const basePath = process.env.ASSET_URL;

export default css`
  .menu-bar .menu-bar-fixed-end {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .menu-bar {
    position: fixed;
    box-sizing: border-box;
    z-index: 10001;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    transform: translateZ(0);

    font-weight: normal;
    color: rgba(var(--menuColor), 1);
    transform: translateZ(0);

    top: 0px;
    left: 0px;
    right: 0px;
    height: 65px;

    transition: transform .3s ease-out,  opacity .3s ease-out, background-color .10s ease-out;
  }

  .menu-bar:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 57px;
    content: "";
    background-color: rgba(var(--menuBg), 1);
    z-index: -1;
  }

  .menu-bar .border {
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
    height: 8px;
    
    display: flex;
    flex-direction: row;
    z-index: -1;
  }

  .menu-bar .border .left {
    display: flex;
    background-color: rgba(var(--menuBg), 1);
    border-bottom: 1px solid rgba(var(--border1), 1);
  }

  .menu-bar .border .arrow {
    display: flex;
    width: 16px;
    height: 8px;
  }
  .menu-bar .border .arrow svg {
    width: 16px;
    height: 8px;
    overflow: hidden;
  }

  .menu-bar .border .right {
    display: flex;
    flex: 1;
    background-color: rgba(var(--menuBg), 1);
    border-bottom: 1px solid rgba(var(--border1), 1);
  }

  .menu-bar :global(*) {
    user-select: none !important;
  }

  .menu-bar :global(button) {
    color: inherit;
    margin-right: 20px;
  }

  .menu-bar :global(a) {
    color: inherit;
  }

  .menu-bar :global(.menu-bar-button) {
    display: inline-block;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    color: rgba(var(--menColor), 1);
    text-decoration: none;
    width: auto;
    max-width: 65px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .menu-bar :global(.menu-bar-button) :global(>svg) {
    width: auto;
    height: 22px;
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    .menu-bar :global(.menu-bar-button.full) {
      max-width: 100%;
      flex-shrink: 0;
    }
    .menu-bar :global(.menu-bar-button.full) :global(.myalbum-logo) {
      width: 119px;
      height: 22px;
      mask: url(${basePath}/_static/fulllogo.svg) no-repeat center left;
    }
  }
  .menu-bar :global(.menu-bar-button+.menu-bar-button) {
    margin-left: 10px;
  }
  .menu-bar .menu-bar-center :global(.menu-bar-button+.menu-bar-button) {
    margin-left: 30px;
  }
  .menu-bar :global(.menu-bar-button:not(.square)+.menu-bar-separator) {
    margin-left: 20px;
  }
  .menu-bar :global(.menu-bar-button.small) {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 40px;
  }
  .menu-bar :global(.menu-bar-button.rounded) :global(>div) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 40px;
    color: #fff;
    background-color: rgba(var(--accentColor), 1);
    border-radius: 28px;
    width: 65px;
  }
  .menu-bar :global(.menu-bar-button.rounded) :global(>div>svg) {
    width: 16px;
    height: 16px;
    transform-origin: 6px 6px;
  }
  .menu-bar :global(.menu-bar-button .spin>div) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }
  .menu-bar :global(.menu-bar-button .spin>div) :global(svg) {
    animation: spinner 1s linear infinite;
    width: 16px;
    height: 16px;
  }
  .menu-bar :global(.menu-bar-button .pulse>div) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
  .menu-bar :global(.menu-bar-button .pulse>div) :global(svg) {
    animation: pulse 5s linear infinite;
    width: 15px;
    height: 15px;
  }
  .menu-bar.hide {
    transform: translateY(-100%);
    opacity: 0;
  }
  .menu-bar :global(.button) {
    border-right: 1px solid rgba(var(--border1), 1);
    border-left: 1px solid rgba(var(--border1), 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: inherit;
    cursor: pointer;
    min-width: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
  }
  .menu-bar :global(.button) svg {
    color: rgba(var(--menuColor), 1);
  }
  .menu-bar :global(.button+.button) {
    border-left: 0;
  }
  .menu-bar.hide {
    transform: translateY(-100%);
  }
  .menu-bar.smallscreen {
    display: flex;
  }
  .menu-bar.smallscreen :global(.menu-bar-button) {
    margin-left: 0px;
  }
  .menu-bar.smallscreen :global(button) {
    margin-right: 10px;
  }
  .menu-bar :global(.myalbum-logo) {
    height: 22px;
    width: 30px;

    mask: url(${basePath}/_static/logo.svg) no-repeat center left;
    background-color: rgba(var(--menuColor), 1);
    opacity: 0.5;
  }

  .menu-bar-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    z-index: 2;
    position: relative;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }
  .menu-bar-center {
    position: absolute;
    left: 0px;
    right: 0px;
    height: inherit;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .menu-bar-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: inherit;
    z-index: 2;
    position: relative;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0.3;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`


const backStyle = css`
  .back {
    box-sizing: border-box;
    width: 61px;
  }

  .back :global(svg) {
    width: 8px;
    height: 15px;
  }
`

const dividerStyle = css`
  .divider {
    height: inherit;
    margin-left: 15px;
    margin-right: 15px;
    border-right: 1px solid rgba(var(--border1), 1);
  }
`;

export {
  backStyle,
  dividerStyle,
}