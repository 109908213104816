export function numberFormat(language, number)
{
  if(!language || isNaN(number))
    return "";

  switch(language.toLowerCase())
  {
    case 'nl': language = "nl-NL"; break;
    case 'de': language = "de-DE"; break;
    case 'fr': language = "fr-FR"; break;
    case 'es': language = "es-ES"; break;
    case 'en': language = "en-US"; break;
  }

  return new Intl.NumberFormat(language).format(number);
}

export function currencyFormat(language, currency, number)
{
  if(!language || !currency || isNaN(number))
    return "";

  switch(language.toLowerCase())
  {
    case 'nl': language = "nl-NL"; break;
    case 'de': language = "de-DE"; break;
    case 'fr': language = "fr-FR"; break;
    case 'es': language = "es-ES"; break;
    case 'en': language = "en-US"; break;
  }

  return new Intl.NumberFormat(language, {style: 'currency', currency: currency}).format(number);
}

export function humanNumber(number) {
  if (number < 1000) {
    return number;
  }

  const exp = Math.floor(Math.log(number) / Math.log(1000));

  return (number / Math.pow(1000, exp)).toFixed(1) + 'kMGTPE'[exp - 1];
}