import { useRef, useEffect, useCallback, useState } from "react";
import { Menu, IconButton, getTheme } from 'components/UI'
import { faBell as notificationIcon } from "@fortawesome/pro-light-svg-icons/faBell";
import { useUser } from "utils/user/lib";
import { Observer } from "mobx-react";
import { getAvatarFromMemberKey } from "utils/avatar";
import translationStore from "stores/translation-store";
import AllNotificationsModal from "../AllNotificationsModal/AllNotificationsModal";
import AllAccessRequestsModal from 'components/Access/AllAccessRequestsModal';
import AllContributionsRequests from "../AllContributionsModal/AllContributionsModal";
import { useNotifications } from "../hooks/useNotifications";

function NotificationMenuItem() {
  const user = useUser();
  const notifications = useNotifications();

  const button = useRef(null);
  const [isMenuOpen, setShowMenu] = Menu.useOpener();
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [showAllAccessRequests, setShowAllAccessRequests] = useState(false);
  const [showAllContributionsRequests, setShowAllContributionsRequests] = useState(false);
  
  const onRequestClose = useCallback(() => {
    setShowMenu(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const showMenu = useCallback(() => {
    setShowMenu(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
      {
        () => {
          if(!user.isLoggedIn)
            return null;

          return (
            <>
              <div
                className="button"
                onPointerDown={showMenu}
                ref={button}
              >
                <IconButton
                  label=""
                  icon={notificationIcon}
                  iconSize={18}
                  iconBalloon={{
                    bg: getTheme().accentColor,
                    color: [0,0,0],
                    value: notifications.balloonCount,
                  }}
                />
              </div>

              <Menu
                roundedBorder={[false, false, true, true]}
                position={{
                  ref: button,
                  hAlign: "right",
                  vAlign: "bottom",
                  x: 0,
                  y: -1,
                }}
                onRequestClose={onRequestClose}
                isOpen={isMenuOpen}
                isLarge={true}
                maxWidth={320}
              >
                <Menu.TextBlock
                  title={translationStore.translate('notification-menu.title')}
                  text={notifications.list.length===0 ? translationStore.translate("notification-menu.no-notifications") : undefined}
                >
                  <Menu.Item
                    title={translationStore.translate('notification-menu.mark-all-as-read')}
                    onClick={notifications.markAllAsRead}
                  />
                </Menu.TextBlock>

                <div style={{width: 320}}>
                {
                  notifications.list.map(notification => (
                    <NotificationItem
                      key={notification.key}
                      notification={notification}
                      onClick={notifications.handleNotification}
                    />
                  ))
                }
                </div>

                {
                  notifications.hasMore &&
                  <Menu.Button
                    title={translationStore.translate('notification-menu.show-all')}
                    fullWidth={true}
                    onClick={() => {
                      setShowAllNotifications(true);
                    }}
                  />
                }

                <Menu.Separator />

                <Menu.Title
                  title={translationStore.translate('notification-menu.requests').toUpperCase()}
                />

                <Menu.Item
                  title={translationStore.translate('notification-menu.access-requests')}
                  onClick={() => {
                    setShowAllAccessRequests(true);
                  }}
                  balloon={{
                    bg: getTheme().balloonBg,
                    color: getTheme().balloonColor,
                    value: notifications.getTotal('access-request'),
                  }}
                />
                <Menu.Item
                  title={translationStore.translate('notification-menu.contribution-requests')}
                  onClick={() => {
                    setShowAllContributionsRequests(true);
                  }}
                  balloon={{
                    bg: getTheme().balloonBg,
                    color: getTheme().balloonColor,
                    value: notifications.getTotal('contribution-request'),
                  }}
                />
              </Menu>

              {
                showAllNotifications && <AllNotificationsModal onClose={() => setShowAllNotifications(false)} />
              }

              {
                showAllAccessRequests && <AllAccessRequestsModal onClose={() => setShowAllAccessRequests(false)} />
              }

              {
                showAllContributionsRequests && <AllContributionsRequests onClose={() => setShowAllContributionsRequests(false)} />
              }

            </>
          )
        }
      }
    </Observer>
  );
}

function NotificationItem({notification, onClick}) {
  const [avatarData, setAvatarData] = useState(notification.sender ? {
    initials: "",
    color: "#ffffff",
    style: {
      border: '1px solid #EFDEFF',
      boxSizing: 'border-box',
    }
  } : (notification.avatar ? notification.avatar : undefined));

  useEffect(() => {
    if(notification.sender)
    {
      const avatar = getAvatarFromMemberKey(notification.sender);
      avatar.then(setAvatarData);
    }

    if(notification.props.subjectImageUrl)
    {
      setAvatarData({color: 'white', image: notification.props.subjectImageUrl});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Menu.Notification
      key={notification.key}
      title={notification.title}
      text={notification.body}
      avatar={avatarData}
      time={notification.created}
      isRead={notification.isRead}
      onClick={() => {
        onClick(notification);
      }}
    />
  );
}

export default NotificationMenuItem;