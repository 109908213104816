import style from "./style";

/**
 * @param {'light-outline'|'accent-filled'} type
 */
export default function Tag({children, type}) {
  const classes = ['tag', type];

  return (
    <div className={classes.join(" ")}>
      {children}
      <style jsx>{style}</style>
    </div>
  );
}