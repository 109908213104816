import LazyList from "@myalbum/lazylist"
import { AvatarMember } from "components/Avatar/Avatar";
import { Loader } from "components/UI";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "utils/user/lib";
import { Stack } from "../UI/ModalV2/ModalV2";
import RequestModal from "./RequestModal";
import style from './style';
import {getRoles} from 'components/Access/utils';
import translationStore from "stores/translation-store";

function AllAccessRequestsModal(props) {
  const user = useUser();
  const _cancel = useRef();

  const [requests, setRequests] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showRequest, setShowRequest] = useState();
  const [nextLink, setNextLink] = useState(`${process.env.API_URL}/access/requests`);

  const loadNext = useCallback(() => {
    if(nextLink) {
      setLoading(true);
      const [promise, cancel] = user.FetchJSON(nextLink);
      _cancel.current = cancel;

      promise.then(({data}) => {
        if(data.links?.next) {
          setNextLink(data.links.next);
        } else {
          setNextLink(null);
        }

        setRequests([
          ...requests,
          ...data.requests
        ]);

        setLoading(false);
      });

      return promise;
    }

    return Promise.resolve();
  }, [nextLink]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadNext();

    return () => {
      if (_cancel.current) {
        _cancel.current();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCloseRequestModal = useCallback((request) => {
    if(request !== undefined) {
      setRequests(current => current.filter(r => r.key !== request.key));
    }
    
    setShowRequest(false);
  }, []);

  const getData = useCallback(() => {
    const items = [];

    if(requests?.length > 0) {
      let lastSubjectID = undefined;
      requests.forEach(request => {
        const subjectID = request.subject.type+'-'+request.subject.key;
        if(lastSubjectID!==subjectID)
        {
          lastSubjectID = subjectID;
          items.push({type: "title", request});
        }

        items.push({type: "request", request});

        return (items);
      });
    }

    return items;
  }, [requests]);

  const getItemLayout = useCallback((item) => {
    switch(item.type) {
      case "title":
        return {size: 95};
      case "request":
        return {size: 56};
      default:
        console.log(`Unknown type: ${item.type}`);
    }
  }, []);

  const renderItem = useCallback((data) => {
    const { type, request } = data;

    switch(type) {
      case "title":
        return (
          <React.Fragment key={request.key+'-title'}>
            <div className="group-title"><h2>{request.subject.title}</h2></div>
            <style jsx>{style}</style>
          </React.Fragment>
        );
      case "request":
        return (
          <React.Fragment key={request.key+"-request"}>
            <div className="request" onClick={() => {
              setShowRequest({request, roles: getRoles(request.subject.type)});
            }}>
              <div className="icon"><AvatarMember memberKey={request.data.memberKey} size={40} /></div>
              <div className="content">
                <div className="name">
                  <span>
                    {request.data.name}
                  </span>
                </div>
              </div>
            </div>
            <style jsx>{style}</style>
          </React.Fragment>
        );
    }
  }, []);

  const ListFooterItem = useCallback(() => {
    if(isLoading) {
      return (
        <Loader />
      );
    }

    return null;
  } , [isLoading]);

  const onEndReached = useCallback(() => {
    return loadNext();
  }, [loadNext]);
  
  return (
    <Stack
      onClose={props.onClose}
      width={550}
      height={800}
    >
      <div className="container">
        <div className="intro">
          <h1>{translationStore.translate('all-requests-modal.title')}</h1>
          <h3>{translationStore.translate('all-requests-modal.text')}</h3>
        </div>

        {
          !requests && <div style={{marginTop: 60}}><Loader /></div>
        }

        <div className="all-request">
          <LazyList
            data={getData()}
            renderItem={renderItem}
            getItemLayout={getItemLayout}
            onEndReached={onEndReached}
            ListFooterItem={ListFooterItem}
            windowSize={3}
          />
        </div>

        {
          requests?.length===0 && <div className="empty">{translationStore.translate('all-requests-modal.empty')}</div>
        }

        {
          showRequest && <RequestModal
            roles={showRequest.roles}
            request={showRequest.request}
            onClose={onCloseRequestModal}
          />
        }

        <style jsx>{style}</style>
      </div>
    </Stack>
  );
}

export default AllAccessRequestsModal;